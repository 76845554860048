import React from "react";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import Icon from "../icon";
import { Icons } from "../icon/types";

import { CalculatorMenuItemProps } from "./types";
import { useAppContext } from "../../core/contexts/appContext";
import { useCalculatorContext } from "../../core/contexts/calculatorContext";

const CalculatorMenuItem = (props: CalculatorMenuItemProps) => {
  const { categoryId, calculator } = props;
  const { currentCalculator } = useCalculatorContext();
  const { closeMenu } = useAppContext();
  const toggleMobileMenu = () => {
    if (isMobileOnly) {
      closeMenu();
    }
  };

  return (
    <li>
      <Link
        to={{ pathname: `/${categoryId}/${calculator.id}` }}
        className={
          currentCalculator && currentCalculator.id === calculator.id
            ? "link active"
            : "link"
        }
        onClick={toggleMobileMenu}
      >
        <span className="icon">
          {currentCalculator && currentCalculator.id === calculator.id && (
            <Icon icon={Icons.ChevronRight} />
          )}
        </span>
        <span className="text">{calculator.title}</span>
      </Link>
    </li>
  );
};

export default CalculatorMenuItem;
