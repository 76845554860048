export interface ApiResult {
  statusCode: number;
}

export const post = async <T>(url: string, body: T): Promise<ApiResult> => {
  const result = await fetch(`https://functions.kalkylo.com/api/${url}`, {
    method: "POST",
    headers: {
      "x-functions-key":
        "86a0R0qAWKQCzK2RguvZrRHGhtg4xTGcBmSagM2O73ngmy2paPXKYQ==",
    },
    body: JSON.stringify(body),
    mode: "cors",
  });

  return {
    statusCode: result.status,
  };
};
