import { Category } from "../../core/domain/types";

const Computing: Category = {
  id: "computing",
  title: "Computing",
  description: "Computing related stuff",
  calculators: [
    {
      id: "base64-decoder",
      title: "Base64 decoder",
      description: "Decode base64 data",
    },
    {
      id: "base64-encoder",
      title: "Base64 encoder",
      description: "Encode data",
    },
  ],
};

export default Computing;
