import "./loader.scss";

import React from "react";
import Spinner from "../../assets/images/spinner.svg";

interface Props {
  text?: string;
}

const Loader = ({ text }: Props) => (
  <div className="loader">
    <img src={Spinner} alt="Loading" />
    {text && <p>{text}</p>}
  </div>
);

Loader.defaultProps = {
  text: undefined,
};

export default Loader;
