import "./search.scss";

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import allCategories from "../../calculators";
import useOutsideClickHandler from "../../utils/outside-click-handler";
import { useAppContext } from "../../core/contexts/appContext";

interface SearchResult {
  categoryId: string;
  categoryTitle: string;
  calculatorId: string;
  calculatorTitle: string;
}

const Search = () => {
  const [query, setQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<SearchResult[]>();
  const nodeRef = useRef<HTMLDivElement>(null);
  const isClickOutside = useOutsideClickHandler(nodeRef);
  const { closeSearch } = useAppContext();

  const searchCalculators = (query: string) => {
    let matchingCalculators: SearchResult[] = [];
    allCategories.forEach((category) => {
      const calculators: SearchResult[] = category.calculators
        .filter((calc) =>
          calc.title.toLowerCase().includes(query.toLowerCase())
        )
        .map((calculator) => ({
          categoryId: category.id,
          categoryTitle: category.title,
          calculatorId: calculator.id,
          calculatorTitle: calculator.title,
        }));
      if (calculators && calculators.length > 0) {
        matchingCalculators = matchingCalculators.concat(calculators);
      }
    });

    setSearchResults(matchingCalculators);
  };

  const clearSearch = () => {
    setQuery("");
    setSearchResults([]);
    closeSearch();
  };

  useEffect(() => {
    if (query && query.length > 0) {
      searchCalculators(query);
    } else {
      setSearchResults([]);
    }
  }, [query]);

  useEffect(() => {
    if (isClickOutside && searchResults) {
      clearSearch();
    }
  }, [isClickOutside]);

  return (
    <div className="search" ref={nodeRef}>
      <input
        className={`text-box search-box ${
          searchResults && searchResults.length > 0 ? "has-results" : ""
        } ${query && query.length > 0 ? "active" : ""}`}
        type="text"
        placeholder="Search for calculators"
        onChange={(e) => setQuery(e.currentTarget.value)}
        value={query}
      />
      {searchResults && searchResults.length > 0 && (
        <div className="search-results">
          {searchResults.map((searchResult) => (
            <div className="search-result" key={searchResult.calculatorId}>
              <Link
                to={`/${searchResult.categoryId}/${searchResult.calculatorId}`}
                className="search-result-link columns space-between"
                onClick={() => clearSearch()}
              >
                <div className="search-result-calculator">
                  {searchResult.calculatorTitle}
                </div>
                <div className="search-result-category">
                  {searchResult.categoryTitle}
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Search;
