import "./cookie-consent.scss";

import React from "react";
import { useAppContext } from "../../core/contexts/appContext";

const CookieConsent = () => {
  const {
    cookieConsentRetrieved,
    acceptCookies,
    declineCookies,
  } = useAppContext();

  return (
    <>
      {!cookieConsentRetrieved && (
        <>
          <div className="cookie-consent-overlay" />
          <div className="cookie-consent-modal-wrapper">
            <div className="cookie-consent-modal">
              <h2>Is it ok if we use cookies?</h2>
              <p>
                We want to use cookies to show you relevant ads and help improve
                our services by analyzing traffic and user behaviour using
                Google Analytics.
              </p>
              <p>
                Regardless of if you choose to accept or decline the use of
                cookies, we will set one small, harmless cookie that keeps track
                of your cookie preferences. Your cookie preferences will be
                saved for 30 days (or until you decide to clear your cookies).
              </p>
              <button
                type="button"
                className="cookie-consent-button accept-cookies"
                onClick={acceptCookies}
              >
                Accept cookies
              </button>
              <button
                type="button"
                className="cookie-consent-button decline-cookies"
                onClick={declineCookies}
              >
                Decline cookies
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CookieConsent;
