import React, { useContext, useState } from "react";
import { Category } from "../domain/types";
import allCategories from "../../calculators/index";
import { ContextProps } from "./types";

interface CategoryContextState {
  categories: Category[];
  currentCategory?: Category;
  setCategory: (categoryId: string) => void;
  clearCategory: () => void;
}

export const CategoryContext = React.createContext<CategoryContextState>({
  categories: allCategories,
  currentCategory: undefined,
  setCategory: () => {},
  clearCategory: () => {},
});

export const useCategoryContext = () => useContext(CategoryContext);

export const CategoryContextProvider = ({ children }: ContextProps) => {
  const [currentCategory, setCurrentCategory] = useState<Category | undefined>(
    undefined
  );

  const setCategory = (categoryId: string) => {
    const category = allCategories.find((cat) => cat.id === categoryId);
    setCurrentCategory(category);
  };

  const clearCategory = () => {
    setCurrentCategory(undefined);
  };

  const state: CategoryContextState = {
    categories: allCategories,
    currentCategory,
    setCategory,
    clearCategory,
  };

  return (
    <CategoryContext.Provider value={state}>
      {children}
    </CategoryContext.Provider>
  );
};
