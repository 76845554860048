import React from "react";
import FeatherIcon from "feather-icons-react";
import { IconProps } from "./types";

const Icon = (props: IconProps) => {
  const { icon } = props;
  return <FeatherIcon icon={icon} />;
};

export default Icon;
