import { Category } from "../../core/domain/types";

const Health: Category = {
  id: "health",
  title: "Health",
  description: "Calculate health related stuff",
  calculators: [
    {
      id: "bmi-calculator",
      title: "BMI calculator",
      description: "Calculate BMI",
    },
    {
      id: "calorie-calculator",
      title: "Calorie calculator",
      description: "Calculate calories",
    },
    {
      id: "ideal-weight-calculator",
      title: "Ideal weight calculator",
      description: "Calculate your ideal weight",
    },
  ],
};

export default Health;
