import React, { useEffect, useState } from "react";
import Icon from "../../components/icon";
import { Icons } from "../../components/icon/types";
import { post, ApiResult } from "../../core/api";

interface Feedback {
  fromName: string;
  fromEmail: string;
  category: string;
  message: string;
}

const Contact = () => {
  const [feedback, setFeedback] = useState<Feedback>({
    fromName: "",
    fromEmail: "",
    category: "",
    message: "",
  });

  const [isValid, setIsValid] = useState<boolean>(false);
  const [result, setResult] = useState<ApiResult | undefined>(undefined);

  useEffect(() => {
    if (
      feedback.fromName.length > 0 &&
      feedback.fromEmail.length > 0 &&
      feedback.category.length > 0 &&
      feedback.message.length > 0
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [feedback]);

  const sendFeedback = async () => {
    const result = await post<Feedback>("Feedback", feedback);
    setResult(result);
  };

  return (
    <>
      <h1>Contact</h1>
      <p>
        Use this form if you wish to get in touch with us! Don&apos;t hesitate
        to ask any questions, or suggest any improvements. We&apos;ll be glad to
        hear from you.
      </p>
      {result && result.statusCode === 200 && (
        <p>Your message has been sent. We will be in touch shortly!</p>
      )}
      {result && result.statusCode !== 200 && (
        <p>Your message could not be sent. Please try again.</p>
      )}
      {(!result || result.statusCode !== 200) && (
        <div className="form">
          <label className="form-element" htmlFor="fromName">
            <div className="label">Your name:</div>
            <div className="control">
              <input
                id="fromName"
                type="text"
                className="text-box"
                value={feedback.fromName}
                onChange={(e) =>
                  setFeedback({ ...feedback, fromName: e.currentTarget.value })
                }
              />
            </div>
          </label>
          <label className="form-element" htmlFor="fromEmail">
            <div className="label">Your e-mail address:</div>
            <div className="control">
              <input
                id="fromEmail"
                type="text"
                className="text-box"
                value={feedback.fromEmail}
                onChange={(e) =>
                  setFeedback({ ...feedback, fromEmail: e.currentTarget.value })
                }
              />
            </div>
          </label>
          <label className="form-element" htmlFor="category">
            <div className="label">Why are you contacting us?</div>
            <div className="control">
              <select
                className="select"
                id="category"
                value={feedback.category}
                onChange={(e) =>
                  setFeedback({ ...feedback, category: e.currentTarget.value })
                }
              >
                <option disabled value="">
                  -- Please choose --
                </option>
                <option value="Bug">To report a bug</option>
                <option value="Improvement">To suggest an improvement</option>
                <option value="GDPR">
                  Privacy concerns or GDPR related issues
                </option>
                <option value="Other">Other</option>
              </select>
            </div>
          </label>
          <label className="form-element" htmlFor="message">
            <div className="label">Message:</div>
            <div className="control">
              <textarea
                id="data"
                className="textarea"
                value={feedback.message}
                onChange={(e) =>
                  setFeedback({ ...feedback, message: e.currentTarget.value })
                }
              />
            </div>
          </label>
          <div className="buttons">
            <button
              type="button"
              className="button primary"
              onClick={sendFeedback}
              disabled={!isValid}
            >
              <Icon icon={Icons.Send} />
              Send message
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;
