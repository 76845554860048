import React from "react";
import CalculatorMenu from "./calculatorMenu";
import Icon from "../icon";
import { Icons } from "../icon/types";
import { CategoryMenuItemProps } from "./types";
import { useCategoryContext } from "../../core/contexts/categoryContext";

const CategoryMenuItem = (props: CategoryMenuItemProps) => {
  const { category } = props;
  const { currentCategory, setCategory, clearCategory } = useCategoryContext();

  const onChangeCategory = () => {
    setCategory(category.id);
    if (category.id !== currentCategory?.id) {
      setCategory(category.id);
    } else if (currentCategory) {
      clearCategory();
    }
  };

  return (
    <li className="category-menu-item">
      <button type="button" onClick={onChangeCategory}>
        <span className="icon">
          <Icon
            icon={
              currentCategory && currentCategory.id === category.id
                ? Icons.ChevronDown
                : Icons.ChevronRight
            }
          />
        </span>
        <span className="text">{category.title}</span>
      </button>
      {currentCategory && currentCategory.id === category.id && (
        <CalculatorMenu
          calculators={category.calculators}
          categoryId={category.id}
        />
      )}
    </li>
  );
};

export default CategoryMenuItem;
