import "./home.scss";

import React from "react";
import { Link } from "react-router-dom";
import { useCategoryContext } from "../../core/contexts/categoryContext";

const Home = () => {
  const { categories } = useCategoryContext();
  return (
    <div>
      <h1>Calculate or convert almost anything</h1>
      <p>
        Kalkylo is home to all sorts of calculators, converters, and much more!
        Use the menu to the left to find your calculator, or use the index
        below. If you&apos;re feeling really wild you might even try out the
        search function in the top right corner. Happy calculating!
      </p>
      <div className="grid grid-2 mobile-grid-1">
        {categories &&
          categories.map((category) => (
            <div key={category.id}>
              <h2>{category.title}</h2>
              <ul className="calculator-index">
                {category.calculators &&
                  category.calculators.map((calculator) => (
                    <li key={calculator.id} className="calculator-index-item">
                      <Link to={`/${category.id}/${calculator.id}`}>
                        {calculator.title}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Home;
