import React, { useEffect, useState } from "react";

const useOutsideClickHandler = (ref: React.RefObject<HTMLElement>) => {
  const [isClickOutside, setIsClickOutside] = useState<boolean>(false);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (ref && ref.current && !ref.current.contains(e.target as Node)) {
        setIsClickOutside(true);
      } else {
        setIsClickOutside(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [ref]);

  return isClickOutside;
};

export default useOutsideClickHandler;
