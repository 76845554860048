import "./footer.scss";

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer>
    <nav>
      <ul>
        <li>Copyright &copy; 2021 Kalkylo</li>
        <li>
          <Link to="/pages/privacy-policy">Privacy policy</Link>
        </li>
        <li>
          <Link to="/pages/cookie-policy">Cookie policy</Link>
        </li>
        <li>
          <Link to="/pages/about">About</Link>
        </li>
        <li>
          <Link to="/pages/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  </footer>
);

export default Footer;
