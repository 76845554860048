import React from "react";

import { CalculatorMenuProps } from "./types";
import CalculatorMenuItem from "./calculatorMenuItem";

const CalculatorMenu = (props: CalculatorMenuProps) => {
  const { categoryId, calculators } = props;
  return (
    <ul className="calculator-menu">
      {calculators.map((calculator) => (
        <CalculatorMenuItem
          calculator={calculator}
          categoryId={categoryId}
          key={calculator.id}
        />
      ))}
    </ul>
  );
};

export default CalculatorMenu;
