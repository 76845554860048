import "./menu.scss";

import React, { useEffect } from "react";
import { matchPath } from "react-router-dom";
import { MenuParams } from "./types";
import CategoryMenuItem from "./categoryMenuItem";
import { useCategoryContext } from "../../core/contexts/categoryContext";

const Menu = () => {
  const { categories, setCategory } = useCategoryContext();

  const onLoad = () => {
    const routeMatch = matchPath<MenuParams>(window.location.pathname, {
      path: "/:categoryId/",
    });

    if (routeMatch?.params !== undefined) {
      const { categoryId } = routeMatch?.params;

      if (categoryId !== undefined) {
        setCategory(categoryId);
      }
    }
  };

  useEffect(onLoad, [categories, window.location.pathname]);

  return (
    <nav className="menu">
      <ul>
        {categories?.map((category) => (
          <CategoryMenuItem category={category} key={category.id} />
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
