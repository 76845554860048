import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { ContextProps } from "./types";

interface AppContextState {
  cookieConsentRetrieved: boolean;
  cookieConsent: boolean;
  mobileMenuOpen: boolean;
  mobileSearchOpen: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  toggleMenu: () => void;
  openSearch: () => void;
  closeSearch: () => void;
  toggleSearch: () => void;
  acceptCookies: () => void;
  declineCookies: () => void;
}

interface ConsentCookie {
  kalkyloconsent?: string;
}

export const AppContext = React.createContext<AppContextState>({
  cookieConsentRetrieved: false,
  cookieConsent: false,
  mobileMenuOpen: false,
  mobileSearchOpen: false,
  openMenu: () => {},
  closeMenu: () => {},
  toggleMenu: () => {},
  openSearch: () => {},
  closeSearch: () => {},
  toggleSearch: () => {},
  acceptCookies: () => {},
  declineCookies: () => {},
});

export const useAppContext = () => useContext(AppContext);

const AppContextProvider = ({ children }: ContextProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [mobileSearchOpen, setMobileSearchOpen] = useState<boolean>(false);

  const [cookies, setCookies] = useCookies(["consent"]);
  const consentCookie = cookies as ConsentCookie;

  const [cookieConsentRetrieved, setCookieConsentRetrieved] = useState<boolean>(
    consentCookie !== undefined && consentCookie.kalkyloconsent !== undefined
  );

  const [cookieConsent, setCookieConsent] = useState<boolean>(
    consentCookie !== undefined && consentCookie.kalkyloconsent === "y"
  );

  const acceptCookies = () => {
    setCookies("kalkyloconsent", "y", {
      maxAge: 60 * 60 * 24 * 30,
    });
    setCookieConsent(true);
    setCookieConsentRetrieved(true);
  };

  const declineCookies = () => {
    setCookies("kalkyloconsent", "n", {
      maxAge: 60 * 60 * 24 * 30,
    });
    setCookieConsent(true);
    setCookieConsentRetrieved(true);
  };

  const openMenu = () => {
    setMobileMenuOpen(true);

    if (mobileSearchOpen) {
      setMobileSearchOpen(false);
    }
  };

  const closeMenu = () => {
    setMobileMenuOpen(false);
  };

  const toggleMenu = () => {
    if (mobileMenuOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const openSearch = () => {
    setMobileSearchOpen(true);

    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };

  const closeSearch = () => {
    setMobileSearchOpen(false);
  };

  const toggleSearch = () => {
    if (mobileSearchOpen) {
      closeSearch();
    } else {
      openSearch();
    }
  };

  const state: AppContextState = {
    cookieConsentRetrieved,
    cookieConsent,
    mobileMenuOpen,
    mobileSearchOpen,
    openMenu,
    closeMenu,
    toggleMenu,
    openSearch,
    closeSearch,
    toggleSearch,
    acceptCookies,
    declineCookies,
  };

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
