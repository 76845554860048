export interface IconProps {
  icon: Icons;
}

export enum Icons {
  ChevronRight = "chevron-right",
  ChevronDown = "chevron-down",
  Menu = "menu",
  Cross = "x",
  Search = "search",
  Copy = "copy",
  ArrowDown = "arrow-down",
  Code = "code",
  Send = "send",
}
