import "./mobile-header.scss";

import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/images/logo_white.svg";
import Icon from "../icon";
import { Icons } from "../icon/types";
import { useAppContext } from "../../core/contexts/appContext";

const MobileHeader = () => {
  const {
    mobileMenuOpen,
    toggleMenu,
    mobileSearchOpen,
    toggleSearch,
  } = useAppContext();

  return (
    <header className="columns">
      <div className="column fill">
        <Link to="/">
          <img src={Logo} className="mobile-logo" alt="Kalkylo logo" />
        </Link>
      </div>
      <div className="column">
        <button
          className="mobile-menu-button"
          type="button"
          onClick={toggleSearch}
        >
          {mobileSearchOpen && <Icon icon={Icons.Cross} />}
          {!mobileSearchOpen && <Icon icon={Icons.Search} />}
        </button>
      </div>
      <div className="column ml-1">
        <button
          className="mobile-menu-button"
          type="button"
          onClick={toggleMenu}
        >
          {mobileMenuOpen && <Icon icon={Icons.Cross} />}
          {!mobileMenuOpen && <Icon icon={Icons.Menu} />}
        </button>
      </div>
    </header>
  );
};

export default MobileHeader;
