import "../../assets/styles/theme.scss";
import "./app.scss";

import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import Footer from "../footer";
import Header from "../header";
import Menu from "../menu";
import Sidebar from "../sidebar";
import Calculator from "../calculator";
import Home from "../../views/home";
import { useCalculatorContext } from "../../core/contexts/calculatorContext";
import { useCategoryContext } from "../../core/contexts/categoryContext";
import PrivacyPolicy from "../../views/privacy-policy";
import CookiePolicy from "../../views/cookie-policy";
import About from "../../views/about";
import Contact from "../../views/contact";
import MobileHeader from "../mobile-header";
import { useAppContext } from "../../core/contexts/appContext";
import Search from "../search";
import CookieConsent from "../cookie-consent";
import enableAnalytics from "../../core/analytics";

const App = () => {
  const { clearCategory } = useCategoryContext();
  const { clearCalculator } = useCalculatorContext();
  const {
    cookieConsent,
    mobileMenuOpen,
    closeMenu,
    mobileSearchOpen,
    closeSearch,
  } = useAppContext();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/" || location.pathname.startsWith("/pages")) {
      clearCategory();
      clearCalculator();
      closeMenu();
      closeSearch();
    }
  }, [location]);

  useEffect(() => {
    if (cookieConsent) {
      enableAnalytics();
    }
  }, [cookieConsent]);

  return (
    <>
      <CookieConsent />
      <div className="app rows">
        <div className="row">
          {!isMobileOnly && <Header />}
          {isMobileOnly && <MobileHeader />}
        </div>
        {mobileMenuOpen && (
          <div className="mobile-menu">
            <Menu />
          </div>
        )}
        {mobileSearchOpen && (
          <div className="mobile-menu">
            <Search />
          </div>
        )}
        {!mobileSearchOpen && !mobileMenuOpen && (
          <>
            <div className="row fill columns mobile-rows mt-2 mb-2 mobile-mt-1">
              {!isMobileOnly && (
                <div className="column one-fifth mobile-full mr-2 mobile-mb-1">
                  <Menu />
                </div>
              )}
              <div className="column fill mobile-full">
                <div className="rows">
                  <div className="row">
                    <Switch>
                      <Route path="/pages/privacy-policy">
                        <PrivacyPolicy />
                      </Route>
                      <Route path="/pages/cookie-policy">
                        <CookiePolicy />
                      </Route>
                      <Route path="/pages/about">
                        <About />
                      </Route>
                      <Route path="/pages/contact">
                        <Contact />
                      </Route>
                      <Route path="/:categoryId/:calculatorId">
                        <Calculator />
                      </Route>
                      <Route path="/">
                        <Home />
                      </Route>
                    </Switch>
                  </div>
                </div>
              </div>
              <div className="column one-fifth mobile-full ml-2 mobile-m-0">
                <Sidebar />
              </div>
            </div>
            <div className="row">
              <Footer />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default App;
