import React, { useEffect, Suspense, lazy } from "react";
import { useParams } from "react-router-dom";
import { CalculatorParams } from "./types";
import { useCalculatorContext } from "../../core/contexts/calculatorContext";
import Loader from "../loader";

const Calculator = () => {
  const { currentCalculator, setCalculator } = useCalculatorContext();
  const { categoryId, calculatorId } = useParams<CalculatorParams>();

  const onLoad = () => {
    setCalculator(categoryId, calculatorId);
  };

  useEffect(onLoad, [categoryId, calculatorId]);

  const CalculatorComponent = lazy(() =>
    import(`../../calculators/${categoryId}/${calculatorId}`).catch(
      () => import(`../not-found`)
    )
  );

  const InformationComponent = lazy(() =>
    import(`../../calculators/${categoryId}/${calculatorId}/information`).catch(
      () => import(`../empty`)
    )
  );

  return (
    <div>
      <h1>{currentCalculator && <span>{currentCalculator.title}</span>}</h1>
      <Suspense fallback={<Loader text="Loading calculator" />}>
        <CalculatorComponent />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <InformationComponent />
      </Suspense>
    </div>
  );
};

export default Calculator;
