import React, { useContext, useState } from "react";
import allCategories from "../../calculators";
import { Calculator } from "../domain/types";
import { ContextProps } from "./types";

interface CalculatorContextState {
  currentCalculator?: Calculator;
  setCalculator: (categoryId: string, calculatorId: string) => void;
  clearCalculator: () => void;
}

export const CalculatorContext = React.createContext<CalculatorContextState>({
  currentCalculator: undefined,
  setCalculator: () => {},
  clearCalculator: () => {},
});

export const useCalculatorContext = () => useContext(CalculatorContext);

export const CalculatorContextProvider = ({ children }: ContextProps) => {
  const [currentCalculator, setCurrentCalculator] = useState<
    Calculator | undefined
  >(undefined);

  const setCalculator = (categoryId: string, calculatorId: string) => {
    const category = allCategories.find((cat) => cat.id === categoryId);
    if (category) {
      const calculator = category.calculators.find(
        (calc) => calc.id === calculatorId
      );

      setCurrentCalculator(calculator);
    }
  };

  const clearCalculator = () => {
    setCurrentCalculator(undefined);
  };

  const state: CalculatorContextState = {
    currentCalculator,
    setCalculator,
    clearCalculator,
  };

  return (
    <CalculatorContext.Provider value={state}>
      {children}
    </CalculatorContext.Provider>
  );
};
