import "./header.scss";

import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo_white.svg";
import Search from "../search";

const Header = () => (
  <header className="columns space-between">
    <div className="column">
      <Link to="/">
        <img src={Logo} className="logo" alt="Kalkylo logo" />
      </Link>
    </div>
    <div className="column search-column">
      <Search />
    </div>
  </header>
);

export default Header;
