import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./components/app";
import AppContextProvider from "./core/contexts/appContext";
import { CategoryContextProvider } from "./core/contexts/categoryContext";
import { CalculatorContextProvider } from "./core/contexts/calculatorContext";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppContextProvider>
        <CategoryContextProvider>
          <CalculatorContextProvider>
            <App />
          </CalculatorContextProvider>
        </CategoryContextProvider>
      </AppContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
