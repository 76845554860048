import "./sidebar.scss";

import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => (
  <div className="sidebar">
    <h3>Missing any calculators?</h3>
    <p>
      Please <Link to="/pages/contact">let us know!</Link>
    </p>
    <h3>Did you know...</h3>
    <p>
      That the word calculator comes from the latin word <em>calculator</em>,
      meaning one who calculates.
    </p>
  </div>
);

export default Sidebar;
